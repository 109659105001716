import * as types from '../types'

const initialState = {
    loading: false,
    resDeviceList: null,
    requestDeviceList: null,
    error: null,
}

export default function deviceListReducer(state = initialState, action) {
    switch (action.type) {
        case types.DEVICE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                requestDeviceList: action.payload.requestDeviceList,
            }

        case types.DEVICE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                resDeviceList: action.payload.resDeviceList,
            }

        case types.DEVICE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                resDeviceList: null,
                requestDeviceList: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}

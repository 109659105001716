import * as types from '../types'

export const deviceListRequest = ({ requestDeviceList }) => ({
    type: types.DEVICE_LIST_REQUEST,
    payload: {
        requestDeviceList,
    },
})

export const deviceListSuccess = ({ resDeviceList }) => ({
    type: types.DEVICE_LIST_SUCCESS,
    payload: {
        resDeviceList,
    },
})

export const deviceListFailure = ({ error }) => ({
    type: types.DEVICE_LIST_FAILURE,
    payload: {
        error: error.message,
    },
})

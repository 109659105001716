import { takeLatest, call, put } from 'redux-saga/effects'
import {
    fetchTicketMoreInfoSuccess,
    fetchTicketMoreInfoFailure,
} from '../actions/ticketMoreInfoAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncTicketMoreInfoSaga(action) {
    try {
        yield put(clearErrors())
        let resTicket = yield call(_api.ticketMoreInfo, {
            requestTicket: action.payload.requestTicket,
        })
        yield put(fetchTicketMoreInfoSuccess({ resTicket }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(fetchTicketMoreInfoFailure({ error: e }))
    }
}
export default function* fetchTicketMoreInfoSaga() {
    yield takeLatest(types.TICKET_MORE_INFO_REQUEST, asyncTicketMoreInfoSaga)
}

import React from 'react'
import { FirebaseDataStyles } from './firebaseData.styles'
import { connect } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'
import { getData } from '../../utils'

const FirebaseData = ({ firebase, firebaseLoading, ...props }) => {
    const classes = FirebaseDataStyles()

    const FirebaseSummaryItem = ({ icon, label, data, loading }) => {
        return (
            <div className={classes.firebaseContent}>
                <p>{icon || label}</p>
                {loading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!data ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span>{data}</span>
                        )}
                    </>
                )}
            </div>
        )
    }

    const firebaseList = [
        {
            label: 'Is app open?:',
            data:
                firebase?.['Is app open?'] === 0
                    ? 'Closed Currently'
                    : 'Open Currently',
        },
        {
            label: 'Engine Status:',
            data: getData(firebase?.['Engine Status']),
        },
        {
            label: 'Last Seen At:',
            data: getData(firebase?.['Last Seen At']),
        },
        {
            label: 'Time Stamp (IST):',
            data: getData(firebase?.['Time Stamp (IST)']),
        },
        {
            label: 'Fuel (litres):',
            data: getData(firebase?.['Fuel (litres)']),
        },
        {
            label: 'Speed:',
            data: getData(firebase?.['Speed']),
        },
        {
            label: 'Latitude:',
            data: getData(firebase?.['Latitude']),
        },
        {
            label: 'Longitude:',
            data: getData(firebase?.['Longitude']),
        },
        {
            label: 'GPS Lock:',
            data: getData(firebase?.['GPS Lock']),
        },
    ]

    return (
        <div>
            <>
                {firebaseList.map((item, index) => (
                    <FirebaseSummaryItem
                        key={index}
                        icon={item.icon || ''}
                        label={item.label || ''}
                        data={item.data}
                        loading={firebaseLoading}
                    />
                ))}
            </>
        </div>
    )
}

const mapStateToProps = (state) => ({
    firebase: state.firebaseReducer.firebaseData,
    firebaseLoading: state.firebaseReducer.loading,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseData)

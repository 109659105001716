import { makeStyles } from '@material-ui/core/styles'

export const homeStyles = makeStyles((theme) => {
    const commonTileStyles = {
        boxShadow: '2px 2px 30px rgba(0,0,0,0.1)',
        '-webkit-box-shadow': '2px 2px 30px rgba(0,0,0,0.1)',
        '-moz-box-shadow': '2px 2px 30px rgba(0,0,0,0.1)',
        borderRadius: '10px',
    }

    return {
        root: {
            width: '100%',
            height: '100%',
        },
        tilesWrapper: {
            maxWidth: '1400px',
            height: '100%',
            margin: '1em auto',
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            padding: '0.5em 0',
        },
        navbar: {
            maxWidth: '1400px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.5em',
            margin: '0.5em auto',
            boxShadow: '0 2px 2px -2px rgba(0,0,0,.2)',
        },
        messageContainer: {
            height: '90vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        messageHeading: {
            textAlign: 'center',
            margin: '3em auto',
            padding: '8em',
        },
        deviceTiles: {
            width: '100%',
            padding: '1em',
            overflowY: 'scroll',
            ...commonTileStyles,
        },
        tiles1: {
            width: '30%',
            height: '900px',
            padding: '1em',
            overflowY: 'scroll',
            ...commonTileStyles,
        },
        map: {
            width: '100%',
            height: '550px',
            margin: '10px',
            overflowY: 'scroll',
            ...commonTileStyles,
        },
        tiles2: {
            width: '30%',
            display: 'flex',
            padding: '1em',
            ...commonTileStyles,
        },
        tiles4: {
            width: '30%',
            height: '900px',
            overflowY: 'scroll',
            ...commonTileStyles,
        },
        tiles5: {
            minWidth: '65%',
            minHeight: '500px',
            ...commonTileStyles,
        },
        tiles6: {
            minWidth: '28%',
            height: '500px',
            ...commonTileStyles,
        },
        tiles7: {
            minWidth: '70%',
            borderRadius: '10px',
        },
        tiles8: {
            width: '100%',
            ...commonTileStyles,
        },
        tiles9: {
            width: '100%',
            ...commonTileStyles,
        },
        newTiles9: {
            width: '100%',
            ...commonTileStyles,
        },
        chartTile: {
            width: '100%',
            margin: '10px',
            ...commonTileStyles,
        },
        chart: {
            width: '100%',
            ...commonTileStyles,
        },
        chartButton: {
            background: '#651fff ',
            height: '45px',
            width: '10em',
            borderRadius: '4px',
            color: '#FFFFFF',
            padding: '0 0.5em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        installationDetailsContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '1em',
        },
        float: {
            position: 'fixed',
            width: '60px',
            height: '60px',
            bottom: '40px',
            right: '40px',
            backgroundColor: '#651fff',
            color: '#FFF',
            borderRadius: '50px',
            textAlign: 'center',
            boxShadow: '2px 2px 3px #999',
            cursor: 'pointer',
            transitionDelay: '10ms',
            '&:hover': {
                width: '62px',
                height: '62px',
            },
        },
    }
})

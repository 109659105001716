import { appDetailsStyles } from './appDetails.styles'
import { connect } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'
import { getTimeStampIST, getData } from '../../utils'

const AppDetails = ({ appData, appLoading }) => {
    const classes = appDetailsStyles()

    const AppSummaryItem = ({ icon, label, data, loading }) => {
        return (
            <div className={classes.appDetailsContent}>
                <p>{icon || label}</p>
                {loading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!data ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span>{data}</span>
                        )}
                    </>
                )}
            </div>
        )
    }

    const appSummaryList = [
        {
            label: 'Last Open App (IST):',
            data: getTimeStampIST(appData?.Last_open_App),
        },
        {
            label: 'App Build:',
            data: getData(appData?.app_build),
        },
        {
            label: 'App Version:',
            data: getData(appData?.app_version),
        },
        {
            label: 'App Language:',
            data: getData(appData?.app_language),
        },
        {
            label: 'Area Unit:',
            data: getData(appData?.area_unit),
        },
        {
            label: 'Brand:',
            data: getData(appData?.brand),
        },
    ]
    return (
        <div>
            {appSummaryList.map((item, index) => (
                <AppSummaryItem
                    key={index}
                    icon={item.icon || ''}
                    label={item.label || ''}
                    data={item.data}
                    loading={appLoading}
                />
            ))}
        </div>
    )
}

const mapStateToProps = (state) => ({
    appData: state.appReducer.app,
    appLoading: state.appReducer.loading,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AppDetails)

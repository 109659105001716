import { makeStyles } from '@material-ui/core/styles'

export const FirebaseDataStyles = makeStyles((theme) => ({
    firebaseContent: {
        display: 'flex',
        gap: '2em',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
        '& > p': {
            margin: 0,
            padding: '0.5em',
            fontSize: '1em',
            color: '#666666',
        },
        '& > span': {
            margin: 0,
            padding: '0.5em',
            fontSize: '1em',
            fontWeight: 'bold',
            display: 'block',
        },
    },
}))

import React, { useState } from 'react'
import { homeStyles } from './home.styles'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { InputField, AccordionComponent } from '../../elements'
import TextField from '@material-ui/core/TextField'

import Dashboard from '../Dashboard'
import FuelSummary from '../FuelSummary'
import Summary from '../Summary'
import AppDetails from '../AppDetails'
import InstAttempts from '../InstAttempts'
import DeviceMount from '../DeviceMounting'
import Antennta from '../Antennta'
import Alternator from '../Alternator'
import Connector from '../Connector'
import Gsmgps from '../GsmGps'
import Ticket from '../Ticket'
import TripReplay from '../TripReplay'
import QualityChart from '../QualityChart'
import FirebaseData from '../FirebaseData'

import { fetchFuelRequest } from '../../store/actions/fetchfuelsAction'
import { fetchSummaryRequest } from '../../store/actions/fetchSummaryAction'
import { fetchFuelSummaryRequest } from '../../store/actions/fetchFuelSummaryAction'
import { fetchFuelModelRequest } from '../../store/actions/fetchFuelModelAction'
import { fetchTicketRequest } from '../../store/actions/fetchTicketAction'
import { fetchAppRequest } from '../../store/actions/fetchAppAction'
import { fetchInstAttemptsRequest } from '../../store/actions/fetchInstAttemptsAction'
import { fetchGsmGpsRequest } from '../../store/actions/fetchGsmGpsAction'
import { fetchInstImageRequest } from '../../store/actions/fetchInstImageAction'
import { fetchFirebaseDataRequest } from '../../store/actions/fetchFirebaseDataAction'
import { fetchThresholdRequest } from '../../store/actions/fetchThresholdValueAction'
import { daySummaryRequest } from '../../store/actions/fetchDaySummaryAction'
import { logoutRequest } from '../../store/actions/logoutAction'
import { simStatusRequest } from '../../store/actions/simStatusAction'
import { replacementDetailRequest } from '../../store/actions/replacemetDetailAction'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { FaRedoAlt } from 'react-icons/fa'

import { getDateToday } from '../../utils'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { deviceListRequest } from '../../store/actions/deviceListAction'
import SimDetails from '../SimDetails'
import ReplacementDetail from '../ReplacementDetail'

function Home({
    fuelLoading,
    fueldata,
    fuelsummary,
    summaryLoading,
    logout,
    ...props
}) {
    const classes = homeStyles()
    const [deviceId, setDeviceId] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [showGraph, setShowGraph] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')

    let deviceData = deviceId
    var today = getDateToday(selectedDate)

    const accordionData = [
        { header: 'Fuel Summary', value: <FuelSummary /> },
        { header: 'App', value: <AppDetails /> },
        { header: 'Sim', value: <SimDetails /> },
        { header: 'Replacement Details', value: <ReplacementDetail /> },
        { header: 'Installation Attempts', value: <InstAttempts /> },
        { header: 'GSM GPS', value: <Gsmgps /> },
        { header: 'Firebase Data', value: <FirebaseData /> },
    ]

    const installationImageData = [
        { header: 'Device Mounting Image', value: <DeviceMount /> },
        { header: 'GPS Antennta Image', value: <Antennta /> },
        { header: 'Alternator Image', value: <Alternator /> },
        { header: 'Fuel Connection Image', value: <Connector /> },
    ]

    const handleChange = (e) => {
        setSelectedDate(e.target.value)
        let deviceDate = e.target.value
        if (deviceId !== 0 && deviceId !== undefined) {
            let requestData = {
                device_id: deviceId,
                date: deviceDate,
            }
            props.fuelSubmit({ requestData })
            props.getDaySummary({ requestData })
        }
    }

    const handleShowGraph = () => {
        setShowGraph((prev) => {
            if (!prev && deviceId !== 0 && deviceId !== undefined) {
                let requestData = {
                    device_id: deviceId,
                    date: today,
                }
                props.getDaySummary({ requestData })
                props.fuelSubmit({ requestData })
            }
            return !prev
        })
    }

    const handleReset = () => {
        let requestData = {
            device_id: deviceId,
            date: today,
        }
        if (deviceId !== undefined && deviceId !== '') {
            let requestDevice = {
                device_id: deviceId,
            }
            props.getDaySummary({ requestData })
            props.fuelSubmit({ requestData })
            props.summary({ requestDevice })
            props.fuelSummary({ requestDevice })
            props.fuelModel({ requestDevice })
            props.ticket({ requestDevice })
            props.app({ requestDevice })
            props.instAttempts({ requestDevice })
            props.gsmGps({ requestDevice })
            props.instImage({ requestDevice })
            props.firebase({ requestDevice })
            props.threshold({ requestDevice })
            props.simRequest({ requestDevice })
            props.replacementDetailReq({ requestDevice })
        }
    }

    const handlePhoneNumber = (e) => {
        const input = e.target.value
        if (input.length <= 10) {
            setPhoneNumber(input)
        }
    }

    const handleDevices = () => {
        let requestDeviceList = {
            phone: phoneNumber,
        }
        props.getDeviceListByPhone({ requestDeviceList })
    }

    return (
        <div className={classes.root}>
            <div className={classes.navbar}>
                <h2 style={{ color: '#651fff' }}>Internal Debug Dashboard</h2>
                <div
                    style={{
                        display: 'flex',
                        gap: '2em',
                        alignItems: 'center',
                    }}
                >
                    <input
                        type="number"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        style={{
                            width: '150px',
                            height: '45px',
                            padding: '5px',
                        }}
                        maxLength={10}
                        onChange={handlePhoneNumber}
                    />
                    <button
                        className={classes.chartButton}
                        onClick={handleDevices}
                    >
                        Get Devices
                    </button>
                    <Formik
                        initialValues={{
                            device_id: '',
                        }}
                        validate={(values) => {
                            const errors = {}
                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let { deviceId } = values
                            setDeviceId(deviceId)
                            if (deviceId !== undefined && deviceId !== '') {
                                let requestDevice = {
                                    device_id: deviceId,
                                }
                                setShowGraph(false)
                                // setSelectedDate(new Date());
                                props.summary({ requestDevice })
                                props.fuelSummary({ requestDevice })
                                props.fuelModel({ requestDevice })
                                props.ticket({ requestDevice })
                                props.app({ requestDevice })
                                props.instAttempts({ requestDevice })
                                props.gsmGps({ requestDevice })
                                props.instImage({ requestDevice })
                                props.firebase({ requestDevice })
                                props.threshold({ requestDevice })
                                props.simRequest({ requestDevice })
                                props.replacementDetailReq({ requestDevice })
                                setSubmitting(false)
                            }
                        }}
                    >
                        {({ values, errors, touched, handleChange }) => (
                            <Form>
                                <div>
                                    <Field
                                        name="deviceId"
                                        id="device-id"
                                        label="Device id"
                                        labelWidth={65}
                                        type="number"
                                        component={InputField}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <form
                        noValidate
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                            width: '40%',
                            color: 'blue',
                        }}
                    >
                        <TextField
                            id="date"
                            type="date"
                            value={selectedDate}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                        />
                    </form>
                </div>
                <button
                    className={classes.chartButton}
                    onClick={handleShowGraph}
                >
                    {!showGraph ? (
                        <>Show Graphs</>
                    ) : (
                        <>
                            <>Hide Graphs</>
                        </>
                    )}
                </button>
                <div onClick={() => logout()}>
                    <Tooltip title="Logout">
                        <IconButton>
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {props.deviceList &&
            props.deviceList?.status &&
            props.deviceList?.data?.device_ids?.length > 0 ? (
                <div className={classes.tilesWrapper}>
                    <div className={classes.deviceTiles}>
                        <b>Device IDs :</b>{' '}
                        {props.deviceList?.data?.device_ids?.join(', ')}
                    </div>
                </div>
            ) : (
                props.deviceList &&
                props.deviceList?.status && (
                    <div className={classes.tilesWrapper}>
                        <div className={classes.deviceTiles}>
                            <b>
                                There is no device ID associated with this
                                number
                            </b>
                        </div>
                    </div>
                )
            )}
            {props.deviceList && !props.deviceList.status && (
                <div className={classes.tilesWrapper}>
                    <div className={classes.deviceTiles}>
                        {props.deviceList?.message}
                    </div>
                </div>
            )}
            {deviceId === 0 || deviceId === undefined || deviceId === '' ? (
                <div className={classes.messageContainer}>
                    <h1 className={classes.messageHeading}>
                        Enter a Device ID
                    </h1>
                </div>
            ) : (
                <>
                    {showGraph && (
                        <div className={classes.tilesWrapper}>
                            <div className={classes.map}>
                                <TripReplay />
                            </div>
                            <div style={{ width: '100%', margin: '1em' }}>
                                <Dashboard device={deviceData} />
                            </div>
                            <div style={{ width: '100%', margin: '1em' }}>
                                <QualityChart device={deviceData} />
                            </div>
                        </div>
                    )}
                    <div className={classes.tilesWrapper}>
                        <div className={classes.tiles1}>
                            <Summary />
                        </div>
                        <div className={classes.tiles4}>
                            {accordionData.map((item, index) => (
                                <div key={index} style={{ margin: '1em' }}>
                                    <AccordionComponent
                                        header={item.header}
                                        value={item.value}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={classes.tilesWrapper}>
                        <div className={classes.newTiles9}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Installation Image
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div
                                        className={
                                            classes.installationDetailsContent
                                        }
                                    >
                                        {installationImageData.map(
                                            (item, index) => (
                                                <AccordionComponent
                                                    key={index}
                                                    header={item.header}
                                                    value={item.value}
                                                />
                                            )
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className={classes.tilesWrapper}>
                        <div className={classes.tiles8}>
                            <AccordionComponent
                                header={'Tickets'}
                                value={<Ticket />}
                            />
                        </div>
                    </div>
                    <div className={classes.float} onClick={handleReset}>
                        <h1 style={{ padding: '10px' }}>
                            <FaRedoAlt />
                        </h1>
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    fuelLoading: state.fuelReducer.loading,
    fuelmodel: state.fuelModelReducer.fuelModel,
    fueldata: state.fuelReducer.fuel,
    fuelsummary: state.fuelSummaryReducer.fuelSummary,
    summaryLoading: state.fuelSummaryReducer.loading,
    deviceList: state.deviceListReducer.resDeviceList,
})

const mapDispatchToProps = (dispatch) => ({
    fuelSubmit: ({ requestData }) =>
        dispatch(fetchFuelRequest({ requestData })),
    summary: ({ requestDevice }) =>
        dispatch(fetchSummaryRequest({ requestDevice })),
    fuelSummary: ({ requestDevice }) =>
        dispatch(fetchFuelSummaryRequest({ requestDevice })),
    fuelModel: ({ requestDevice }) =>
        dispatch(fetchFuelModelRequest({ requestDevice })),
    ticket: ({ requestDevice }) =>
        dispatch(fetchTicketRequest({ requestDevice })),
    app: ({ requestDevice }) => dispatch(fetchAppRequest({ requestDevice })),
    instAttempts: ({ requestDevice }) =>
        dispatch(fetchInstAttemptsRequest({ requestDevice })),
    gsmGps: ({ requestDevice }) =>
        dispatch(fetchGsmGpsRequest({ requestDevice })),
    instImage: ({ requestDevice }) =>
        dispatch(fetchInstImageRequest({ requestDevice })),
    firebase: ({ requestDevice }) =>
        dispatch(fetchFirebaseDataRequest({ requestDevice })),
    threshold: ({ requestDevice }) =>
        dispatch(fetchThresholdRequest({ requestDevice })),
    getDaySummary: ({ requestData }) =>
        dispatch(daySummaryRequest({ requestData })),
    getDeviceListByPhone: ({ requestDeviceList }) =>
        dispatch(deviceListRequest({ requestDeviceList })),
    simRequest: ({ requestDevice }) =>
        dispatch(simStatusRequest({ requestDevice })),
    replacementDetailReq: ({ requestDevice }) =>
        dispatch(replacementDetailRequest({ requestDevice })),
    logout: () => {
        dispatch(logoutRequest())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)

import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import {
    loginRequestSuccess,
    loginRequestFailure,
} from '../actions/loginAction'
import {
    logoutRequestSuccess,
    logoutRequestFailure,
} from '../actions/logoutAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'

function* asyncLoginRequest(action) {
    try {
        yield put(clearErrors())
        const res = yield call(_api.loginUser, {
            email: action.payload.email,
            password: action.payload.password,
        })
        if (res.status) {
            localStorage.setItem(
                'access_token',
                res.data.auth_tokens.access_token
            )
            localStorage.setItem(
                'refresh_token',
                res.data.auth_tokens.refresh_token
            )
            yield put(
                loginRequestSuccess({
                    status: res.status,
                    message: res.message,
                })
            )
        } else {
            yield put(
                loginRequestFailure({
                    status: res.status,
                    message: res.message,
                })
            )
        }
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(loginRequestFailure({ status: false, message: e.message }))
    }
}

function* asyncLogoutRequest(action) {
    try {
        yield put(logoutRequestSuccess())
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(logoutRequestFailure(e))
    }
}

export default function* loginRequestSaga() {
    yield put(clearErrors())
    yield takeLatest(types.LOGIN_REQUEST, asyncLoginRequest)
}

export function* logoutRequestSaga() {
    yield takeLatest(types.LOGOUT_REQUEST, asyncLogoutRequest)
}

import * as types from '../types'

export const fetchTicketMoreInfoRequest = ({ requestTicket }) => ({
    type: types.TICKET_MORE_INFO_REQUEST,
    payload: {
        requestTicket,
    },
})

export const fetchTicketMoreInfoSuccess = ({ resTicket }) => ({
    type: types.TICKET_MORE_INFO_SUCCESS,
    payload: {
        resTicket,
    },
})

export const fetchTicketMoreInfoFailure = ({ error }) => ({
    type: types.TICKET_MORE_INFO_FAILURE,
    payload: {
        error: error.message,
    },
})

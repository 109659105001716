import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReactFusioncharts from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import charts from 'fusioncharts/fusioncharts.charts'
import CircularProgress from '@material-ui/core/CircularProgress'

// Resolves charts dependancy
charts(FusionCharts)

function DelayChart({ fuelLoading, fueldata, summary, summaryLoading }) {
    const [series, setSeries] = useState([])

    useEffect(() => {
        if (fueldata && fueldata.message === undefined) {
            let timeStampData = Object.values(fueldata.time_stamp)
            let delayData = Object.values(fueldata.firmware_version)
            let data = []

            let count = 0
            for (let i = 0; i < timeStampData.length; i++) {
                if (delayData[i] !== -1) {
                    count++
                }
            }

            let size
            if (count < 100) {
                size = '6'
            } else {
                size = '0.5'
            }

            for (let i = 0; i < timeStampData.length; i++) {
                if (delayData[i] === -1) {
                    data.push({
                        label: timeStampData[i],
                    })
                } else if (delayData[i] < 1) {
                    let hourConvert = delayData[i] / 3600
                    data.push({
                        label: timeStampData[i],
                        // value: delayData[i],
                        value: hourConvert,
                        drawAnchors: '1',
                        anchorRadius: '1',
                        anchorBorderThickness: '3',
                        anchorBorderColor: '#FF0000',
                        anchorSides: '3',
                        anchorBgColor: '#d3f7ff',
                    })
                } else {
                    let hourConvert = delayData[i] / 3600
                    data.push({
                        label: timeStampData[i],
                        // value: delayData[i],
                        value: hourConvert,
                        drawAnchors: '1',
                        anchorRadius: '1',
                        // anchorBorderThickness: "0.5",
                        anchorBorderThickness: size,
                        anchorBorderColor: '#FFCC00',
                        anchorSides: '1',
                        anchorBgColor: '#FFCC00',
                    })
                }
            }

            setSeries(data)
        }
    }, [fueldata, summary, summaryLoading])

    const chartConfigs = {
        chart: {
            xAxisName: 'TimeStamp (IST)',
            yAxisName: 'Delay (hours)',
            showValues: '0',
            showBorder: '0',
            showCanvasBorder: '0',
            drawAnchors: '0',
            // numberSuffix: "L",
            theme: 'fusion',
            xAxisNameFontSize: '20',
            xAxisNameFontColor: '#651fff',
            yAxisNameFontSize: '20',
            yAxisNameFontColor: '#651fff',
            labelStep: '60',
            baseFontSize: '11',
            plottooltext:
                "<div>TimeStamp : <b>$label</b></div><hr class='demo'>Delay : <b>$dataValue</b>",
        },
        data: series,
    }

    function ChartData() {
        // let timeStammp = Object.values(fueldata.time_stamp);
        if (fuelLoading) {
            return (
                <div style={{ textAlign: 'center', padding: '5em' }}>
                    <CircularProgress />
                </div>
            )
        } else if (
            fueldata &&
            fueldata.message === undefined &&
            Object.values(fueldata.time_stamp).length > 0
        ) {
            if (!summaryLoading) {
                if (
                    summary?.[0].hw_batch === 49 ||
                    summary?.[0].hw_batch === 52
                ) {
                    return (
                        <h3 style={{ textAlign: 'center', padding: '5em' }}>
                            No Data for HW Batch 49 and 52
                        </h3>
                    )
                } else {
                    return (
                        <ReactFusioncharts
                            type="line"
                            dataFormat="JSON"
                            width="100%"
                            height="600"
                            dataSource={chartConfigs}
                        />
                    )
                }
            }
        } else {
            return (
                <h1 style={{ textAlign: 'center', padding: '5em' }}>
                    {fueldata?.message}
                </h1>
            )
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {!fueldata ? (
                <div style={{ textAlign: 'center', padding: '5em' }}>
                    <CircularProgress />
                </div>
            ) : (
                ChartData()
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    fueldata: state.fuelReducer.fuel,
    fuelLoading: state.fuelReducer.loading,
    summary: state.summaryReducer.summary,
    summaryLoading: state.summaryReducer.loading,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DelayChart)

import React from 'react'

import { Switch, Route } from 'react-router-dom'
import { PrivateRoute } from '../elements'
import { Home, Login, Dashboard } from '../components'

import { connect } from 'react-redux'

const Routes = ({ isLoggedIn }) => {
    return (
        <>
            <Switch>
                <Route exact path="/login">
                    <Login />
                </Route>
                <PrivateRoute exact path="/" isAuthenticated={isLoggedIn}>
                    <Home />
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path="/charts/:ticketId/:id/:date/:time"
                    isAuthenticated={isLoggedIn}
                >
                    <Dashboard />
                </PrivateRoute>
            </Switch>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.loginReducer.isLoggedIn,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)

import { summaryStyles } from './summary.styles'
import { connect } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'
import {
    getData,
    getDate,
    getTimeSummary,
    boolValue,
    getDateDiff,
} from '../../utils'
import {
    FaMapMarkerAlt,
    FaMobileAlt,
    FaUserAlt,
    FaBroadcastTower,
    FaTractor,
} from 'react-icons/fa'

const Summary = ({
    summary,
    summaryLoading,
    appData,
    appLoading,
    simLoading,
    sim,
    ...props
}) => {
    const classes = summaryStyles()

    const DetailItem = ({ icon, label, data, loading }) => {
        return (
            <div className={classes.summaryContainer}>
                <p>{icon || label}</p>
                {loading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!data ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span>{data}</span>
                        )}
                    </>
                )}
            </div>
        )
    }

    const detailItems = [
        {
            icon: <FaUserAlt />,
            data: getData(summary?.[0].user_name),
        },
        {
            icon: <FaMobileAlt />,
            data: getData(summary?.[0].phone),
        },
        {
            icon: <FaMapMarkerAlt />,
            data: `${summary?.[0]?.location}, ${summary?.[0]?.district}, ${summary?.[0]?.state}`,
        },
        {
            icon: <FaTractor />,
            label: '',
            data: getData(summary?.[0]?.tractor_name),
        },
        {
            label: 'Model:',
            data: getData(summary?.[0]?.model),
        },
        {
            label: 'Machinery Type:',
            data: getData(summary?.[0]?.category),
        },
        {
            icon: <FaBroadcastTower />,
            data: getData(summary?.[0]?.operator),
        },
        {
            label: 'Sim Active:',
            data: sim?.sim_status,
        },
        {
            label: 'Replaced Device ID:',
            data: getData(summary?.[0]?.label),
        },
        {
            label: 'HW Batch:',
            data: getData(summary?.[0]?.hw_batch),
        },
        {
            label: 'Installation Date:',
            data: getDate(summary?.[0]?.installation_date),
        },
        {
            label: 'Subscription Due Date:',
            data: getDateDiff(summary?.[0]?.subscription_due_date),
        },
        {
            label: 'Dealer Code:',
            data: getData(summary?.[0]?.dealer_code),
        },
        {
            label: 'Area Manager:',
            data: getData(summary?.[0]?.Area_Manager),
        },
        {
            label: 'Last Ping Time (IST):',
            data: getTimeSummary(summary?.[0]?.last_ping_time),
        },
        {
            label: 'Loc Time Stamp (IST):',
            data: getTimeSummary(summary?.[0]?.loc_time_stamp),
        },
        {
            label: 'Customer registered on App:',
            data: boolValue(summary?.[0]?.Customer_registered_on_customer_app),
        },
        {
            label: 'App First Login Date:',
            data: getDate(summary?.[0]?.App_first_login_date),
        },
        {
            label: 'Firm Name:',
            data: getData(summary?.[0]?.firm_name),
        },
        {
            label: 'Subscription Days:',
            data: getData(summary?.[0]?.subscription_days),
        },
    ]

    const compareVersions = (version1, version2) => {
        const v1 = version1.split('.').map(Number)
        const v2 = version2.split('.').map(Number)

        for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
            const num1 = v1[i] || 0
            const num2 = v2[i] || 0

            if (num1 > num2) return 1
            if (num1 < num2) return -1
        }
        return 0
    }

    const getAppVersionColor = (build, version) => {
        if (build === 'mahindra') {
            return compareVersions(version, '1.9.0') >= 0
                ? '#00FF00'
                : '#FF0000'
        } else if (build === 'simha') {
            return compareVersions(version, '27.8.0') >= 0
                ? '#00FF00'
                : '#FF0000'
        }
        return '#000000'
    }

    return (
        <div>
            <h1>Summary</h1>
            <hr></hr>
            <div className={classes.summaryContainer}>
                <p>Device Details:</p>
                {summaryLoading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!summary ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span>{getData(summary?.[0].device_id)}</span>
                        )}
                    </>
                )}
            </div>
            <>
                {detailItems.map((item, index) => (
                    <DetailItem
                        key={index}
                        icon={item.icon || ''}
                        label={item.label || ''}
                        data={item.data}
                        loading={summaryLoading}
                    />
                ))}
            </>
            <div className={classes.summaryContainer}>
                <p>App Version:</p>
                {appLoading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!appData ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span
                                style={{
                                    color: getAppVersionColor(
                                        appData.app_build,
                                        appData.app_version
                                    ),
                                }}
                            >
                                {appData.app_version}
                            </span>
                        )}
                    </>
                )}
            </div>
            <div className={classes.summaryContainer}>
                <p>Device Tags:</p>
                {summaryLoading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!summary ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span>{summary?.[0]?.device_tag.join(' , ')}</span>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    summary: state.summaryReducer.summary,
    summaryLoading: state.summaryReducer.loading,
    appData: state.appReducer.app,
    appLoading: state.appReducer.loading,
    sim: state.simStatusReducer.sim,
    simLoading: state.simStatusReducer.loading,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Summary)

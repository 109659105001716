import { makeStyles } from '@material-ui/core/styles'

export const modalStyles = makeStyles((theme) => ({
    modalWrapper: {
        position: 'fixed',
        left: '0',
        right: '0',
        bottom: '0',
        top: '0',
        background: 'rgba(198, 198, 198, 0.9)',
        zIndex: '1',
    },
    modalContainer: {
        position: 'fixed',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '80vw',
        minWidth: '300px',
        maxWidth: '100vw',
        height: '400px',
        overflowY: 'scroll',
        padding: '1rem',
        borderRadius: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        zIndex: '1',
    },
    closeModal: {
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '10px',
        cursor: 'pointer',
    },
    tableHeader: {
        fontSize: '16px',
        fontWeight: '600',
    },
}))

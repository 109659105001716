import React from 'react'
import { fuelsummaryStyles } from './fuelSummary.styles'
import { connect } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const FuelSummary = ({
    fuelsummary,
    fuelSummaryLoading,
    fuelmodel,
    modelLoading,
    ...props
}) => {
    const classes = fuelsummaryStyles()

    const FuelSummaryItem = ({ icon, label, data, loading }) => {
        return (
            <div className={classes.fuelSummaryContent}>
                <p>{icon || label}</p>
                {loading ? (
                    <Skeleton animation="wave" width={100} />
                ) : (
                    <>
                        {!data ? (
                            <Skeleton animation="wave" width={100} />
                        ) : (
                            <span>{data}</span>
                        )}
                    </>
                )}
            </div>
        )
    }

    const fuelSummaryList = [
        {
            label: 'Fuel Refill Delta:',
            data: fuelsummary?.fuel_refill_delta[0],
        },
        {
            label: 'Alert enable register:',
            data: fuelsummary?.alert_enable_register[0],
        },
        {
            label: 'Obd Method:',
            data: fuelsummary?.obd_method[0],
        },
        {
            label: 'Fuel Tank Capacity:',
            data: fuelmodel?.fuel_tank_capacity[0],
        },
    ]

    const fuelCoefficients = [
        { label: 'Fuel coef a', value: fuelsummary?.fuel_coef_a[0] },
        { label: 'Fuel coef b', value: fuelsummary?.fuel_coef_b[0] },
        { label: 'Fuel coef c', value: fuelsummary?.fuel_coef_c[0] },
        { label: 'Fuel coef d', value: fuelsummary?.fuel_coef_d[0] },
    ]

    const temperatureCoefficients = [
        { label: 'Temp coef c', value: fuelsummary?.temp_coef_c[0] },
        { label: 'Temp coef b', value: fuelsummary?.temp_coef_b[0] },
    ]

    return (
        <div>
            <>
                {fuelSummaryList.map((item, index) => (
                    <FuelSummaryItem
                        key={index}
                        icon={item.icon || ''}
                        label={item.label || ''}
                        data={item.data}
                        loading={fuelSummaryLoading || modelLoading}
                    />
                ))}
            </>
            <div>
                <div className={classes.text} style={{ margin: '0.5em 0' }}>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    {fuelCoefficients.map((coeff) => (
                                        <TableCell key={coeff.label}>
                                            {coeff.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {fuelCoefficients.map((coeff) => (
                                        <TableCell
                                            key={coeff.label}
                                            component="th"
                                            scope="row"
                                        >
                                            {fuelSummaryLoading ? (
                                                <Skeleton
                                                    animation="wave"
                                                    width={40}
                                                />
                                            ) : (
                                                <>
                                                    {!fuelsummary ? (
                                                        <Skeleton
                                                            animation="wave"
                                                            width={40}
                                                        />
                                                    ) : (
                                                        <h5>{coeff.value}</h5>
                                                    )}
                                                </>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.text} style={{ margin: '0.5em 0' }}>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    {temperatureCoefficients.map((coeff) => (
                                        <TableCell key={coeff.label}>
                                            {coeff.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {temperatureCoefficients.map((coeff) => (
                                        <TableCell
                                            key={coeff.label}
                                            component="th"
                                            scope="row"
                                        >
                                            {fuelSummaryLoading ? (
                                                <Skeleton
                                                    animation="wave"
                                                    width={40}
                                                />
                                            ) : (
                                                <>
                                                    {!fuelsummary ? (
                                                        <Skeleton
                                                            animation="wave"
                                                            width={40}
                                                        />
                                                    ) : (
                                                        <h5>{coeff.value}</h5>
                                                    )}
                                                </>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    fuelsummary: state.fuelSummaryReducer.fuelSummary,
    fuelSummaryLoading: state.fuelSummaryReducer.loading,
    fuelmodel: state.fuelModelReducer.fuelModel,
    modelLoading: state.fuelModelReducer.loading,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FuelSummary)

import { combineReducers } from 'redux'
import errorReducer from './errorReducer'
import fetchFuelReducer from './fetchFuelReducer'
import fetchSummaryReducer from './fetchSummaryReducer'
import fetchFuelSummaryReducer from './fetchFuelSummaryReducer'
import fetchFuelModelReducer from './fetchFuelModelReducer'
import fetchTicketReducer from './fetchTicketReducer'
import fetchAppReducer from './fetchAppReducer'
import fetchInstAttemptsReducer from './fetchInstAttemptsReducer'
import fetchGsmGpsReducer from './fetchGsmGpsReducer'
import fetchInstImageReducer from './fetchInstImageReducer'
import fetchFirebaseDataReducer from './fetchFirebaseDataReducer'
import fetchThresholdReducer from './fetchThresholdReducer'
import daySummaryReducer from './fetchDaySummaryReducer'
import loginReducer from './loginReducer'
import fetchFuelTicketReducer from './fetchFuelTicketReducer'
import fuelCloseTicketReducer from './fuelTicketReducer'
import ticketMoreInfoReducer from './ticketMoreInfoReducer'
import deviceListReducer from './deviceListReducer'
import simStatusReducer from './simStatusReducer'
import replacementDetailReducer from './replacementDetailReducer'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'fuelReducer',
        'summaryReducer',
        'fuelSummaryReducer',
        'fuelModelReducer',
        'fuelCulpritReducer',
        'ticketReducer',
        'appReducer',
        'instAttemptsReducer',
        'firebaseReducer',
        'thresholdReducer',
        'daySummData',
        'fuelTicketReducer',
        'fuelCloseTicketReducer',
        'simStatusReducer',
        'replacementDetailReducer',
        'deviceListReducer',
    ],
}

const rootReducer = combineReducers({
    errorReducer,
    fuelReducer: fetchFuelReducer,
    summaryReducer: fetchSummaryReducer,
    fuelSummaryReducer: fetchFuelSummaryReducer,
    fuelModelReducer: fetchFuelModelReducer,
    ticketReducer: fetchTicketReducer,
    appReducer: fetchAppReducer,
    instAttemptsReducer: fetchInstAttemptsReducer,
    gsmGpsReducer: fetchGsmGpsReducer,
    instImageReducer: fetchInstImageReducer,
    firebaseReducer: fetchFirebaseDataReducer,
    thresholdReducer: fetchThresholdReducer,
    daySummData: daySummaryReducer,
    fuelTicketReducer: fetchFuelTicketReducer,
    fuelCloseTicketReducer,
    ticketMoreInfoReducer,
    deviceListReducer,
    simStatusReducer,
    replacementDetailReducer,
    loginReducer,
})

export default persistReducer(persistConfig, rootReducer)

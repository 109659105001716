export const API_ROUTES = {
    LOGIN: '/cms/tech/api/login/',
    FUELS: '/debug/get_trip_info/',
    DEVICE_SUMMARY: '/debug/dev/idp/summary/',
    DEVICE_FUEL_SUMMARY: '/debug/get_fuel_summary/',
    MODEL: '/debug/get_fuel_model/',
    DEVICE_TICKET: '/debug/get_ticket_summary/',
    APP: '/debug/get_app_data/',
    INSTALLATION: '/debug/get_inst_attempts/',
    GSM_GPS: '/debug/get_gsm_info/',
    INSTALLATION_IMAGE: '/debug/get_installation_images/',
    FIREBASE: '/cms/check_presence/',
    THRESHOLD: '/debug/get_vbat_thr/',
    REFRESH_ACCESS_TOKEN: '/users/refresh_access_token/',
    CLOSE_TICKET: '/cms/close_ticket/',
    FUEL_TICKET: '/cms/get_fuel_ticket_metadata/',
    TICKET_MORE_INFO: '/cms/support_history/',
    DEVICE_LIST: '/debug/get_devices_by_phone/',
    SIM: '/debug/get_sim_summary/',
    REPLACEMENT_DETAIL: '/debug/get_replacement_summary/',
}

export const API_BASE_URL = {
    LOGIN_URL: process.env.REACT_APP_INTERNALS
        ? process.env.REACT_APP_INTERNALS
        : 'https://staging.carnot-traclytics.com',
    DATA_URL: process.env.REACT_APP_DATA
        ? process.env.REACT_APP_DATA
        : 'https://staging.carnot-traclytics.com',
    FIELD_URL: process.env.REACT_FIELD_URL
        ? process.env.REACT_FIELD_URL
        : 'https://field-lambda.carnot-traclytics.com',
}

import * as types from '../types'

const initialState = {
    loading: false,
    summary: null,
    requestDevice: null,
    error: null,
}

export default function fetchSummaryReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true,
                requestDevice: action.payload.requestDevice,
            }

        case types.FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                summary: action.payload.summary,
            }

        case types.FETCH_SUMMARY_FAILURE:
            return {
                ...state,
                loading: false,
                summary: null,
                requestDevice: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}

import { takeLatest, call, put } from 'redux-saga/effects'
import {
    deviceListSuccess,
    deviceListFailure,
} from '../actions/deviceListAction'
import { returnErrors, clearErrors } from '../actions/errorAction'
import * as _api from '../../api'
import * as types from '../types'

function* asyncDeviceListSaga(action) {
    try {
        yield put(clearErrors())
        let resDeviceList = yield call(_api.getDeviceList, {
            requestDeviceList: action.payload.requestDeviceList,
        })
        yield put(deviceListSuccess({ resDeviceList }))
    } catch (e) {
        yield put(returnErrors({ msg: e.message, status: true }))
        yield put(deviceListFailure({ error: e }))
    }
}
export default function* deviceListSaga() {
    yield takeLatest(types.DEVICE_LIST_REQUEST, asyncDeviceListSaga)
}

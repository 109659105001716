import { all } from 'redux-saga/effects'
import fetchFuelSaga from './fetchFuelSaga'
import fetchSummarySaga from './fetchSummarySaga'
import fetchFuelSummarySaga from './fetchFuelSummarySaga'
import fetchFuelModelSaga from './fetchFuelModelSaga'
import fetchTicketSaga from './fetchTicketSaga'
import fetchAppSaga from './fetchAppSaga'
import fetchInstAttemptsSaga from './fetchInstAttemptsSaga'
import fetchGsmGpsSaga from './fetchGsmGpsSaga'
import fetchInstImageSaga from './fetchInstImageSaga'
import fetchFirebaseDataSaga from './fetchFirebaseDataSaga'
import fetchThresholdSaga from './fetchThresholdSaga'
import daySummaryRequestSaga from './fetchDaySummarySaga'
import loginRequestSaga, { logoutRequestSaga } from './loginSaga'
import fetchFuelTicketSaga from './fetchFuelTicketSaga'
import fuelCloseTicketSaga from './fuelTicketSaga'
import fetchTicketMoreInfoSaga from './ticketMoreInfoSaga'
import deviceListSaga from './deviceListSaga'
import simStatusSaga from './simStatusSaga'
import replacementDetailSaga from './replacementDetailSaga'

export default function* rootSaga() {
    yield all([
        fetchFuelSaga(),
        fetchSummarySaga(),
        fetchFuelSummarySaga(),
        fetchFuelModelSaga(),
        fetchTicketSaga(),
        fetchAppSaga(),
        fetchInstAttemptsSaga(),
        fetchGsmGpsSaga(),
        fetchInstImageSaga(),
        fetchFirebaseDataSaga(),
        fetchThresholdSaga(),
        daySummaryRequestSaga(),
        loginRequestSaga(),
        logoutRequestSaga(),
        fetchFuelTicketSaga(),
        fuelCloseTicketSaga(),
        simStatusSaga(),
        replacementDetailSaga(),
        fetchTicketMoreInfoSaga(),
        deviceListSaga(),
    ])
}

import * as types from '../types'

const initialState = {
    loading: false,
    resTicket: null,
    requestTicket: null,
    error: null,
}

export default function ticketMoreInfoReducer(state = initialState, action) {
    switch (action.type) {
        case types.TICKET_MORE_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                requestTicket: action.payload.requestTicket,
            }

        case types.TICKET_MORE_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                resTicket: action.payload.resTicket,
            }

        case types.TICKET_MORE_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                resTicket: null,
                requestTicket: null,
                error: action.payload.error,
            }

        default:
            return state
    }
}

import axios from 'axios'
import { parseDayDetails } from '../utils'
import { API_ROUTES, API_BASE_URL } from '../config'
import { store } from '../store'
import { logoutRequest } from '../store/actions/logoutAction'

// const headerParams = { 'app-version': 26.6 }
const headerJWTParams = {
    'app-build': 'testapp',
    'app-version': '24.5',
    platform: 'Android',
}

const axiosInstance = axios.create({
    baseURL: API_BASE_URL.DATA_URL,
    headers: {
        ...headerJWTParams,
    },
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token')
        if (token) {
            config.headers['User-Auth'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            try {
                const refreshed = await getRefreshAccessToken()
                if (refreshed) {
                    const originalRequest = error.config
                    originalRequest.headers[
                        'User-Auth'
                    ] = `Bearer ${localStorage.getItem('access_token')}`
                    return axiosInstance(originalRequest)
                }
            } catch (refreshError) {
                throw refreshError
            }
        }
        throw error
    }
)

export const loginUser = async ({ email, password }) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${API_BASE_URL.LOGIN_URL}${API_ROUTES.LOGIN}`,
            data: JSON.stringify({ user_id: email, user_pwd: password }),
            headers: {
                'content-type': 'application/json',
            },
        })
        return data
    } catch (error) {
        console.err(error)
        throw error
    }
}

export const fuels = async ({ requestData }) => {
    try {
        const { data } = await axiosInstance.post(
            `${API_ROUTES.FUELS}`,
            requestData
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceSummary = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.post(
            `${API_ROUTES.DEVICE_SUMMARY}${device}/`,
            requestDevice
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceFuelSummary = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.DEVICE_FUEL_SUMMARY}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceFuelModel = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.MODEL}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceTicket = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.DEVICE_TICKET}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceApp = async ({ requestDevice }) => {
    try {
        let device = requestDevice.device_id
        const { data } = await axiosInstance.get(`${API_ROUTES.APP}${device}/`)
        return data
    } catch (error) {
        throw error
    }
}

export const deviceAttempts = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.INSTALLATION}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceGsmGps = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.GSM_GPS}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceInstImg = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.INSTALLATION_IMAGE}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const firebaseData = async ({ requestDevice }) => {
    try {
        let device = requestDevice.device_id
        const { data } = await axios({
            method: 'post',
            url: `${API_BASE_URL.DATA_URL}${API_ROUTES.FIREBASE}${device}`,
            data: requestDevice,
        })
        return data
    } catch (error) {
        throw error
    }
}

export const thresholdValue = async ({ requestDevice }) => {
    try {
        let { device_id: device } = requestDevice
        const { data } = await axiosInstance.get(
            `${API_ROUTES.THRESHOLD}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const fetchDaySummary = async ({ requestData }) => {
    let deviceId = requestData.device_id
    let date = new Date(requestData.date)
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let today = date.getDate()
    if (month < 10) {
        month = '0' + month
    }

    if (today < 10) {
        today = '0' + today
    }

    const { data } = await axiosInstance.get(
        `/data/summary/day/${deviceId}/${year}/${month}/${today}/0/`
    )

    return data
}

export const fetchDayDetail = async ({ deviceId, date }) => {
    let datee = new Date(date)
    let month = datee.getMonth() + 1
    let year = datee.getFullYear()
    let today = datee.getDate()

    if (month < 10) {
        month = '0' + month
    }

    if (today < 10) {
        today = '0' + today
    }

    const {
        data: { data },
    } = await axiosInstance.get(
        `/data/details/day/${deviceId}/${year}/${month}/${today}/0/`
    )
    return parseDayDetails(data)
}

export const getRefreshAccessToken = async () => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${API_BASE_URL.DATA_URL}${API_ROUTES.REFRESH_ACCESS_TOKEN}`,
            headers: {
                ...headerJWTParams,
                'User-Auth': `Bearer ${localStorage.getItem('refresh_token')}`,
            },
        })
        localStorage.setItem('access_token', data.access_token)
        return true
    } catch (error) {
        sessionStorage.setItem('loggedIn', false)
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        store.dispatch(logoutRequest())
        throw error
    }
}

export const getFuelTicket = async ({ requestTicket }) => {
    const { data } = await axiosInstance.get(
        `${API_ROUTES.FUEL_TICKET}${requestTicket.id}/`
    )

    return data
}

export const closeFuelTicket = async ({ requestCloseFuelTicket }) => {
    try {
        const { data } = await axiosInstance.post(
            `${API_ROUTES.CLOSE_TICKET}`,
            requestCloseFuelTicket
        )
        return data
    } catch (error) {
        throw error
    }
}

export const ticketMoreInfo = async ({ requestTicket }) => {
    try {
        let { ticket_id: ticket } = requestTicket
        const { data } = await axiosInstance.get(
            `${API_ROUTES.TICKET_MORE_INFO}${ticket}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

export const getDeviceList = async ({ requestDeviceList }) => {
    try {
        const { data } = await axiosInstance.post(
            `${API_ROUTES.DEVICE_LIST}`,
            requestDeviceList
        )
        return data
    } catch (error) {
        throw error
    }
}

export const deviceSim = async ({ requestDevice }) => {
    try {
        let device = requestDevice.device_id
        const { data } = await axiosInstance.get(`${API_ROUTES.SIM}${device}/`)
        return data
    } catch (error) {
        throw error
    }
}

export const getReplacementDetail = async ({ requestDevice }) => {
    try {
        let device = requestDevice.device_id
        const { data } = await axiosInstance.get(
            `${API_ROUTES.REPLACEMENT_DETAIL}${device}/`
        )
        return data
    } catch (error) {
        throw error
    }
}

import { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import { modalStyles } from './modal.styles'

export const Modal = ({ ticket, setModalOpen, ...props }) => {
    const classes = modalStyles()
    const [ticketData, setTicketData] = useState([])

    useEffect(() => {
        document.body.style.overflowY = 'hidden'

        return () => {
            document.body.style.overflowY = 'scroll'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(ticket)
        if (ticket?.status) {
            setTicketData(ticket.data)
            console.log(ticketData, ticket.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket])

    return (
        <>
            <div
                className={classes.modalWrapper}
                onClick={() => setModalOpen(false)}
            ></div>
            <div className={classes.modalContainer}>
                <div
                    className={classes.closeModal}
                    onClick={() => setModalOpen(false)}
                >
                    Close
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>
                                    Account Assigned to
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                    Account Operated From
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                    Close By
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                    Activity
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                    Tag
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                    Action
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                    Date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ticketData?.map((row, index) => (
                                <TableRow key={row[0] || index}>
                                    {' '}
                                    <TableCell component="th" scope="row">
                                        {row[0] || 'NA'}
                                    </TableCell>
                                    <TableCell>{row[1] || 'NA'}</TableCell>
                                    <TableCell>{row[2] || 'NA'}</TableCell>
                                    <TableCell>{row[3] || 'NA'}</TableCell>
                                    <TableCell>{row[4] || 'NA'}</TableCell>
                                    <TableCell>{row[5] || 'NA'}</TableCell>
                                    <TableCell>{row[6] || 'NA'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    ticket: state.ticketMoreInfoReducer.resTicket,
    ticketLoading: state.ticketReducer.loading,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
